import { SnackbarContext } from '@invisible/common/providers'
import { fromGlobalId } from '@invisible/concorde/gql-client'
import { logger } from '@invisible/logger/client'
import { useContext, useQuery } from '@invisible/trpc/client'
import { Text } from '@invisible/ui/text'
import { gray, styled } from '@invisible/ui/themes'
import { Box, Grid, LinearProgress, Popover, Typography } from '@mui/material'
import { compact, flatten } from 'lodash'
import { KeyboardEvent, useContext as useReactContext, useMemo, useReducer, useState } from 'react'
import sanitize from 'sanitize-html'
import { useGate } from 'statsig-react'

import { useBaseRunCreateMany } from '../../hooks/useBaseRunCreateManyWizardAction'
import { useBaseRunDeleteWithStepRunReference } from '../../hooks/useBaseRunDeleteWithStepRunReference'
import { TEXT_ANNOTATION_STATE_INITIAL_VALUES } from './constants'
import {
  getShortcut,
  removeEnclosingTagsAroundUrls,
  selectionIsBackwards,
  selectionIsEmpty,
  splitWithOffsets,
} from './helpers'
import Split from './Split'
import { AnnotationSidebar } from './sub-components/AnnotationSidebar'
import { EntitySidebar } from './sub-components/EntitySidebar'
import { OptionsContextMenu } from './sub-components/OptionsContextMenu'
import { ITextAnnotationWACProps, TAnnotation, TAnnotationState } from './types'

const Container = styled.div`
  border-radius: 8px;
  border: 1px solid ${gray(4)};
  height: 100%;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.024) 0px 2px 4px;
  background-color: white;
  overflow: hidden;
`

export const TextAnnotationWAC = ({
  value,
  showName,
  name,
  stepRun,
  baseRun,
  textAnnotation: config,
}: ITextAnnotationWACProps) => {
  const reactQueryContext = useContext()
  const annotationBaseId = config?.annotationBaseId as string
  const { showSnackbar } = useReactContext(SnackbarContext)
  const { value: enableRichTextUrlParsing } = useGate('enable-richtext-url-parsing')
  const [annotationStack, setAnnotationStack] = useState<string[]>([])
  const [entity, setEntity] = useState('')
  const [option, setOption] = useState<string | null>(null)

  const [{ anchorEl, annotation, isHighlighting, selections = [], isKeyPressed }, dispatch] =
    useReducer(
      (prev: TAnnotationState, next: TAnnotationState) => ({ ...prev, ...next }),
      TEXT_ANNOTATION_STATE_INITIAL_VALUES
    )

  const sanitizedHtml = useMemo(() => {
    const sanitizedContent = sanitize(value as string, {
      allowedTags: [],
      allowedAttributes: {},
    })

    if (enableRichTextUrlParsing && typeof value === 'string') {
      return removeEnclosingTagsAroundUrls(sanitizedContent)
    }

    return sanitizedContent
  }, [enableRichTextUrlParsing, value])

  const handleKeyDown = (event: KeyboardEvent<HTMLElement>) =>
    event.metaKey || event.ctrlKey ? dispatch({ isKeyPressed: true }) : null

  const { mutateAsync: createBaseRuns, isLoading: createBaseRunsLoading } = useBaseRunCreateMany({
    onSuccess: () => {
      showSnackbar({
        message: 'Annotations Saved!',
        variant: 'success',
      })
    },
    onError: (error) => {
      logger.error('Error creating Text Annotation(s)', {
        error,
        baseRunId: baseRun.id,
        stepRunId: stepRun.id,
      })
      showSnackbar({ message: `Failed to create annotation(s)`, variant: 'error' })
    },
    onSettled: () => {
      reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
      dispatch(TEXT_ANNOTATION_STATE_INITIAL_VALUES)
    },
  })

  const { mutateAsync: deleteBaseRuns, isLoading: deleteBaseRunLoading } =
    useBaseRunDeleteWithStepRunReference({
      onSuccess: () => {
        reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
        if (annotation) {
          setAnnotationStack(annotationStack.filter((id) => id !== annotation.id))
        } else {
          setAnnotationStack(annotationStack.slice(0, annotationStack.length - 1))
        }
        dispatch(TEXT_ANNOTATION_STATE_INITIAL_VALUES)
        showSnackbar({
          message: 'Annotation Deleted',
          variant: 'success',
        })
      },
      onError: (error) => {
        dispatch(TEXT_ANNOTATION_STATE_INITIAL_VALUES)
        logger.error('Error deleting Text annotation', {
          error,
          baseRunId: baseRun.id,
          stepRunId: stepRun.id,
        })
        showSnackbar({ message: `Failed to Deleted annotation`, variant: 'error' })
      },
    })

  const { data: annotations, isLoading: queryAnnotationsLoading } = useQuery([
    'baseRun.findChildBaseRuns',
    {
      baseId: annotationBaseId,
      parentBaseRunId: baseRun.id,
    },
  ])

  const { data, isLoading } = useQuery(['baseVariable.findByBaseId', { baseId: baseRun.baseId }])

  const normalizedAnnotations = useMemo(
    () =>
      (annotations ?? [])
        .map((annotation) => ({
          id: annotation.id,
          text: annotation.baseRunVariables.find(
            (variable) => variable.baseVariable.id === config?.textBaseVariableId
          )?.value as string,
          start: annotation.baseRunVariables.find(
            (variable) => variable.baseVariable.id === config?.startBaseVariableId
          )?.value as number,
          end: annotation.baseRunVariables.find(
            (variable) => variable.baseVariable.id === config?.endBaseVariableId
          )?.value as number,
          entity: annotation.baseRunVariables.find(
            (variable) => variable.baseVariable.id === config?.assignedEntityBaseVariableId
          )?.value as string,
          option: annotation.baseRunVariables.find(
            (variable) => variable.baseVariable.id === config?.selectedOptionBaseVariableId
          )?.value as string,
          createdAt: annotation.createdAt,
        }))
        .sort((a, b) => a.start - b.end),
    [annotations, config]
  )

  const validateSelection = (start: number, end: number) => {
    if (start >= end) return false

    const checkIfOverlapped = (data: Array<TAnnotation>): boolean =>
      data?.some(
        (annotation: TAnnotation) =>
          start < annotation?.end &&
          end > annotation?.start &&
          !(start === annotation?.start && end === annotation?.end) &&
          ((start <= annotation?.start && end >= annotation?.end) ||
            (start >= annotation?.start && end <= annotation?.end))
      )

    if (checkIfOverlapped(normalizedAnnotations) || checkIfOverlapped(selections)) {
      showSnackbar({
        message: 'Invalid Sub-String / Selection',
        variant: 'warning',
      })
      return false
    }

    return true
  }

  const handleMouseUp = () => {
    if (createBaseRunsLoading) return

    const selection: Selection | null = window.getSelection()

    if (!selection || selectionIsEmpty(selection)) return

    if (!entity)
      return showSnackbar({
        message: 'Please select an Entity First',
        variant: 'info',
      })

    const { anchorNode, focusNode, anchorOffset, focusOffset } = selection
    let start =
      parseInt(anchorNode?.parentElement?.getAttribute('data-start') || '0', 10) + anchorOffset
    let end =
      parseInt(focusNode?.parentElement?.getAttribute('data-start') || '0', 10) + focusOffset

    if (selectionIsBackwards(selection)) {
      ;[start, end] = [end, start]
    }

    if (!validateSelection(start, end)) return

    const clonedContent = selection.getRangeAt(0).cloneContents()
    clonedContent.querySelectorAll('span[data-shortcut]').forEach((span) => span.remove())
    const text = clonedContent ? clonedContent.textContent || '' : selection.toString()

    dispatch({
      selections: [...selections, { start, end, text }],
      isHighlighting: true,
      annotation: { start, end, text },
    })
  }

  const saveAnnotationToBaseRun = async () => {
    if (!selections || selections.length === 0 || createBaseRunsLoading) return

    const { baseRunCreateManyWizardAction: baseRuns } = await createBaseRuns({
      baseId: annotationBaseId as string,
      parentBaseRunId: baseRun.id,
      initialValuesArray: flatten(selections).map((annotation) => [
        {
          baseVariableId: config?.textBaseVariableId as string,
          value: annotation.text,
        },
        {
          baseVariableId: config?.startBaseVariableId as string,
          value: annotation.start,
        },
        {
          baseVariableId: config?.endBaseVariableId as string,
          value: annotation.end,
        },
        {
          baseVariableId: config?.assignedEntityBaseVariableId as string,
          value: entity || '',
        },
        {
          baseVariableId: config?.selectedOptionBaseVariableId as string,
          value: option || '',
        },
      ]),
      sourceStepRunId: stepRun.id,
    })
    setOption(null)
    setAnnotationStack([
      ...annotationStack,
      ...baseRuns?.map((baseRun) => fromGlobalId(baseRun?.id)),
    ])
  }

  const handleDeleteBaseRun = async (base_run_id: string) => {
    if (!window.confirm('Are you sure you want to delete this annotation?')) return

    await deleteBaseRuns({ baseRunIds: [base_run_id], stepRunId: stepRun.id })
  }

  const handleKeyPress = (event: KeyboardEvent<HTMLElement>) => {
    event.preventDefault()
    event.stopPropagation()

    const { key, ctrlKey, metaKey } = event
    if (key === 'Enter') {
      if (isHighlighting) {
        saveAnnotationToBaseRun()
      }
    }
    if (key === 'z' && ctrlKey) {
      if (annotationStack.length > 0) {
        const id = annotationStack[annotationStack.length - 1]

        deleteBaseRuns({ baseRunIds: [id], stepRunId: stepRun.id })
      } else {
        showSnackbar({ message: 'Nothing to undo', variant: 'warning' })
      }
    }

    if (isKeyPressed && !metaKey) dispatch({ isKeyPressed: false })
  }

  const splits = splitWithOffsets(
    sanitizedHtml,
    compact(isHighlighting ? [...selections, ...normalizedAnnotations] : normalizedAnnotations)
  )

  return (
    <>
      {annotation ? (
        <Popover
          open={Boolean(anchorEl) && !isKeyPressed}
          anchorEl={anchorEl}
          onClose={() => dispatch(TEXT_ANNOTATION_STATE_INITIAL_VALUES)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}>
          {isHighlighting ? (
            <OptionsContextMenu
              save={saveAnnotationToBaseRun}
              setOption={setOption}
              selectedOption={option}
              options={data?.find((row) => row.id === config?.optionsBaseVariableId)?.options}
              entity={entity}
              selections={selections?.map((row) => row?.text)}
            />
          ) : (
            <Box sx={{ width: 250, p: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', color: 'gray' }}>
                <span className='whitespace-normal text-xs'>{annotation.entity}</span>
                <span className='text-xs'>{getShortcut(annotation.entity as string)}</span>
              </Box>
              <p className='mt-2 mb-3 whitespace-normal font-semibold'>{annotation.text}</p>
            </Box>
          )}
        </Popover>
      ) : null}
      <Container>
        <Grid container height='100%'>
          <Grid sm={3} height='100%'>
            {isLoading ? <LinearProgress color='secondary' /> : null}
            <EntitySidebar
              setEntity={setEntity}
              activeEntity={entity}
              entities={
                data?.find((row) => row.id === config?.entitiesBaseVariableId)?.options || []
              }
            />
          </Grid>
          <Grid sm={6} height='100%' overflow='auto'>
            {createBaseRunsLoading || queryAnnotationsLoading || deleteBaseRunLoading ? (
              <LinearProgress color='secondary' />
            ) : null}

            <Box sx={{ px: 2 }}>
              {showName ? (
                <Text
                  style={{ padding: '10px 0px', borderBottom: '1px solid rgba(0, 0, 0, 0.08)' }}
                  mb='10px'
                  fontSize={16}
                  fontWeight='bold'>
                  {name}
                </Text>
              ) : null}
              <div
                className='whitespace-pre-wrap leading-[1.65rem]'
                tabIndex={0}
                onKeyUp={handleKeyPress}
                onMouseUp={handleMouseUp}
                onKeyDown={handleKeyDown}>
                {splits.map((split, index) => (
                  <Split
                    key={`${split.start}-${split.end}`}
                    {...split}
                    isHighlighted={Boolean(
                      annotation &&
                        (annotation.start === split.start || annotation.end === split.end)
                    )}
                    dispatcher={dispatch}
                    rightOffset={
                      split?.mark && splits[index + 1]?.mark
                        ? splits[index + 1]?.end - split.end
                        : 0
                    }
                  />
                ))}
              </div>
            </Box>
          </Grid>
          <Grid sm={3} height='100%'>
            {isLoading ? <LinearProgress color='secondary' /> : null}
            <AnnotationSidebar
              isLoading={deleteBaseRunLoading || createBaseRunsLoading || queryAnnotationsLoading}
              annotations={normalizedAnnotations}
              deleteAnnotation={handleDeleteBaseRun}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
