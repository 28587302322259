import { Button, ButtonShape, ButtonSize, ButtonVariant } from '@invisible/ui/button'
import { DeleteFilledIcon, FileOutlineIcon } from '@invisible/ui/icons'
import { Icons } from '@invisible/ui/icons'
import { LogoSpinner } from '@invisible/ui/logo-spinner'
import { Modal } from '@invisible/ui/modal'
import { Tooltip } from '@invisible/ui/tooltip'
import AddIcon from '@mui/icons-material/Add'
import IconButton from '@mui/material/IconButton'
import axios from 'axios'
import { get } from 'lodash/fp'
import pMap from 'p-map'
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import * as uuid from 'uuid'

import { GOOGLE_CLOUD_BUCKET_NAME } from '../config/env'

const GOOGLE_CLOUD_API = 'https://storage.googleapis.com'
const GOOGLE_CLOUD_PRIVATE_API = 'https://storage.cloud.google.com' // for authenticated requests

type ErrorCardProps = {
  message: string
  visible?: boolean
  deleteCard: () => void
}

type InfoCardProps = {
  name: string
  deleteFile: () => void
}

type IUploaderDropzoneProps = {
  acceptedFileTypes?: string | string[]
  directoryName: string
  saveUploadedFiles: (files: UploadedFile[]) => void
  setFileCount?: Dispatch<SetStateAction<number>>
  maxUploadAllowed?: number
  disabled?: boolean
  defaultValues?: string[]
  bucketName?: string
  setShowModal: Dispatch<SetStateAction<boolean>>
  uploadedFiles: UploadedFile[]
  setUploadedFiles: Dispatch<SetStateAction<UploadedFile[]>>
}

type UploadButtonIconShape = Exclude<ButtonShape, 'default'>

type IUploadModal = Omit<IUploaderDropzoneProps, 'setUploadedFiles' | 'uploadedFiles'> & {
  showModal?: boolean
}

type IFileUploaderDropzone = {
  acceptedFileTypes?: string | string[]
  directoryName: string
  saveUploadedFiles: (files: UploadedFile[]) => void
  showFileCount?: boolean
  uploadButtonSize?: ButtonSize
  uploadButtonType?: ButtonVariant
  maxUploadAllowed?: number
  disabled?: boolean
  defaultValues?: string[]
  bucketName?: string
  uploadButtonIcon?: keyof typeof Icons
  uploadButtonShape?: UploadButtonIconShape
  showMuiIconButton?: boolean
}

export type UploadedFile = {
  fileUuid: string
  fileName: string
  url: string
  size: number
  type: string
  filePath: string
}

// eslint-disable-next-line @typescript-eslint/ban-types
const ErrorCard: FC<ErrorCardProps> = ({ message, deleteCard }) => (
  <div className='space-y-2 rounded-md border border-solid border-red-200 bg-red-100 p-2'>
    <div className='flex items-center justify-between'>
      <p className='font-bold text-red-600'>Upload Failed</p>
      <DeleteFilledIcon
        width={15}
        height={15}
        className='cursor-pointer text-gray-400 hover:text-gray-600'
        onClick={() => deleteCard()}
      />
    </div>

    <span>{message}</span>
  </div>
)

// eslint-disable-next-line @typescript-eslint/ban-types
const InfoCard: FC<InfoCardProps> = ({ name, deleteFile }) => (
  <div className='flex items-center justify-between rounded-md p-2 hover:border hover:border-solid hover:border-gray-200 hover:bg-gray-100'>
    <span className='w-[80%]'>{name}</span>

    <DeleteFilledIcon
      width={15}
      height={15}
      className='cursor-pointer text-gray-400 hover:text-gray-600'
      onClick={() => deleteFile()}
    />
  </div>
)

// eslint-disable-next-line @typescript-eslint/ban-types
export const UploaderDropzone: FC<IUploaderDropzoneProps> = ({
  acceptedFileTypes,
  directoryName,
  bucketName,
  saveUploadedFiles,
  setFileCount,
  maxUploadAllowed,
  defaultValues,
  setShowModal,
  setUploadedFiles,
  uploadedFiles,
}) => {
  const [rejections, setRejections] = useState<string[]>([])

  const [uploading, setUploading] = useState(false)

  const fileReader = async (file: File) => {
    const reader = new FileReader()

    return new Promise((resolve, reject) => {
      reader.onabort = () => reject(new Error('File reading was aborted'))
      reader.onerror = () => new Error('An error occurred while reading the file')
      reader.onload = () => {
        const binaryStr = reader.result
        resolve(binaryStr)
      }
      reader.readAsArrayBuffer(file)
    })
  }

  const uploadFiles = async (files: File[], directoryName?: string) => {
    await pMap(
      files,
      async (file) => {
        await fileReader(file).then(async () => {
          try {
            const fileName = encodeURIComponent(file.name.trim().replace(/\s+/g, '_')) // Replace all whitespaces with underscores

            const fileUuid = uuid.v4()

            const GOOGLE_API =
              bucketName && bucketName.includes('private')
                ? GOOGLE_CLOUD_PRIVATE_API
                : GOOGLE_CLOUD_API

            const url = `${GOOGLE_API}/${
              bucketName ?? GOOGLE_CLOUD_BUCKET_NAME
            }/${directoryName}/${fileUuid}${fileName}`

            const signedUrl = await axios.post(
              `/api/google/cloud/signedUrl?file=${directoryName}/${fileUuid}${fileName}&type=${
                file.type
              }&bucketName=${bucketName ?? GOOGLE_CLOUD_BUCKET_NAME}`
            )

            await axios.put(get('url', signedUrl.data), file, {
              headers: { 'Content-Type': file.type },
            })

            const filePath = `gs://${
              bucketName ?? GOOGLE_CLOUD_BUCKET_NAME
            }/${directoryName}/${fileUuid}${fileName}`

            setUploadedFiles((prev) => [
              ...prev,
              { fileName, url, size: file.size, type: file.type, fileUuid, filePath },
            ])
          } catch (err: any) {
            const errorMessage = err.response?.data?.errorMessage || err.message
            setRejections((prev) => [
              ...prev,
              `An error ocurred while uploading ${file.name}`,
              errorMessage,
            ])
          }
        })
      },
      { concurrency: 1 }
    )

    setUploading(false)
  }

  const onDrop = useCallback(async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    setUploading(true)

    fileRejections.forEach((rejection) => {
      setRejections((prev) => [...prev, `${rejection.file.name} ${rejection.errors[0].message}`])
    })

    await uploadFiles(acceptedFiles, directoryName)
  }, [])

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept: acceptedFileTypes,
    maxFiles: maxUploadAllowed,
    onDrop,
    noClick: true,
  })

  useEffect(
    () => () => {
      setRejections([])
      if (defaultValues?.length === 0) {
        setUploadedFiles([])
      }
    },
    []
  )

  return (
    <div className='min-w-[400px] max-w-[600px]'>
      <div className='h-[425px] space-y-2 overflow-y-auto overflow-x-hidden p-4'>
        <div
          {...getRootProps()}
          className='flex h-[225px] w-full items-center justify-center rounded-md border border-dashed border-gray-200 bg-gray-100'>
          <div className='space-y-2'>
            <input hidden {...getInputProps()} />
            {isDragActive ? (
              <p>Drop it like it&apos; hot!</p>
            ) : (
              <>
                <div className='flex justify-center'>
                  <FileOutlineIcon width={20} height={20} className='self-center' />
                </div>
                <p>Drop file(s) to upload</p>
                <p className='text-center'>OR</p>
                <div className='flex w-full justify-center'>
                  <Button size='sm' variant='secondary' onClick={open}>
                    Browse
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
        {uploading ? (
          <div className='flex items-center justify-center gap-2'>
            <LogoSpinner width={25} height={25} />
            <p>Uploading...</p>
          </div>
        ) : null}

        {rejections?.map((error, i) => (
          <ErrorCard
            message={error}
            deleteCard={() => setRejections(rejections.filter((error, index) => index !== i))}
          />
        ))}
        {uploadedFiles?.map((file, i) => (
          <InfoCard
            key={i}
            name={`${file.fileName}`}
            deleteFile={() => setUploadedFiles(uploadedFiles.filter((file, index) => index !== i))}
          />
        ))}
      </div>
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const UploadModal: FC<IUploadModal> = ({
  showModal,
  setShowModal,
  defaultValues,
  saveUploadedFiles,
  setFileCount,
  ...props
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([])
  useEffect(() => {
    if (defaultValues && defaultValues?.length > 0) {
      const defaultUploadedFiles = defaultValues?.map((value) => ({
        fileName: value.substring(value.lastIndexOf('/') + 1),
        url: value,
        size: 0,
        type: 'image/jpeg',
        filePath: value,
      }))
      setUploadedFiles(defaultUploadedFiles as UploadedFile[])
    }
  }, [defaultValues])

  return showModal ? (
    <Modal
      visible={showModal}
      onClose={() => setShowModal(false)}
      title='Upload File(s)'
      primaryButton={
        <Button
          variant='primary'
          size='md'
          disabled={uploadedFiles.length === 0}
          onClick={() => {
            saveUploadedFiles(uploadedFiles)
            if (setFileCount) setFileCount(uploadedFiles.length)
            setShowModal(false)
          }}>
          Save
        </Button>
      }
      secondaryButton={
        <Button variant='secondary' size='md' onClick={() => setShowModal(false)}>
          Cancel
        </Button>
      }>
      <UploaderDropzone
        {...props}
        saveUploadedFiles={saveUploadedFiles}
        setShowModal={setShowModal}
        setUploadedFiles={setUploadedFiles}
        uploadedFiles={uploadedFiles}
      />
    </Modal>
  ) : null
}

// eslint-disable-next-line @typescript-eslint/ban-types
const FileUploaderDropzone: FC<IFileUploaderDropzone> = ({
  acceptedFileTypes,
  directoryName,
  bucketName,
  saveUploadedFiles,
  showFileCount = true,
  uploadButtonSize = 'sm',
  uploadButtonType = 'primary',
  maxUploadAllowed,
  disabled = false,
  defaultValues = [],
  uploadButtonIcon,
  uploadButtonShape,
  showMuiIconButton = false,
}) => {
  const [showModal, setShowModal] = useState(false)
  const [fileCount, setFileCount] = useState(0)

  const defaultFiles: string[] = []

  if (defaultValues.length > 0) {
    // eslint-disable-next-line array-callback-return
    defaultValues.map((value) => {
      if (value.length > 0) {
        defaultFiles.push(value)
      }
    })
  }

  return (
    <div>
      <div className='flex items-center gap-2'>
        {showMuiIconButton ? (
          <Tooltip arrow side='top' content={'Upload Attachments'}>
            <IconButton onClick={() => setShowModal(true)}>
              <AddIcon fontSize='small' />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip arrow side='top' content={'Upload Attachments'}>
            <Button
              size={uploadButtonSize}
              variant={uploadButtonType}
              icon={uploadButtonIcon}
              onClick={() => setShowModal(true)}
              disabled={disabled}
              shape={uploadButtonIcon ? uploadButtonShape || 'circle' : undefined}>
              {!uploadButtonIcon && 'Upload'}
            </Button>
          </Tooltip>
        )}

        {showFileCount && fileCount > 0 ? <span>{fileCount} file(s) uploaded</span> : null}
      </div>

      <UploadModal
        bucketName={bucketName}
        showModal={showModal}
        setShowModal={setShowModal}
        acceptedFileTypes={acceptedFileTypes}
        directoryName={directoryName}
        saveUploadedFiles={saveUploadedFiles}
        setFileCount={setFileCount}
        maxUploadAllowed={maxUploadAllowed}
        defaultValues={defaultFiles}
      />
    </div>
  )
}

export { FileUploaderDropzone }
