import { CodeWAC } from '@invisible/common/components/process-base'
import { classNames } from '@invisible/common/helpers'
import { SnackbarContext } from '@invisible/common/providers'
import { Button } from '@invisible/ui/button'
import { Card } from '@invisible/ui/card'
import { DateTimePicker } from '@invisible/ui/date-time-picker'
import { Dropdown } from '@invisible/ui/dropdown'
import type { UploadedFile } from '@invisible/ui/file-uploader'
import { FileUploaderDropzone } from '@invisible/ui/file-uploader'
import { TextArea } from '@invisible/ui/form'
import { InfoFilledIcon } from '@invisible/ui/icons'
import { Input } from '@invisible/ui/input'
import { MultiSelect } from '@invisible/ui/multi-select'
import { NullSwitch } from '@invisible/ui/null-switch'
import { Progress } from '@invisible/ui/progress'
import { QuillEditor } from '@invisible/ui/quill-editor'
import { Radio } from '@invisible/ui/radio'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import { Box, Input as MuiInput, TextField } from '@mui/material'
import { format } from 'date-fns/fp'
import { zonedTimeToUtc } from 'date-fns-tz'
import { isEmpty, isNil, size } from 'lodash/fp'
import dynamic from 'next/dynamic'
import { ChangeEvent, FC, Fragment, useContext, useEffect, useMemo, useState } from 'react'
import { v4 as uuid } from 'uuid'

import { TBaseRunQueryData } from '../../../process-base/src'
import { CheckList } from './components/CheckList'
import { EmbedLoomVideo } from './components/EmbedLoomVideo'
import { Preview } from './components/Preview'
import { Section } from './components/Section'

type TCheckListValue = Record<string, boolean>
type TBaseRun = TBaseRunQueryData['items'][number]
type TBaseRunVariable = TBaseRun['baseRunVariables'][number]

const getNumberHelperText = (
  min: number | undefined,
  max: number | undefined
) => {
  if (Number.isInteger(min) && Number.isInteger(max)) {
    return `Value must be in range [${min}-${max}].`
  } else if (Number.isInteger(min) && !Number.isInteger(max)) {
    return `Value must be greater than or equal to ${min}.`
  } else if (!Number.isInteger(min) && Number.isInteger(max)) {
    return `Value must be less than or equal to ${max}.`
  } else {
    return ''
  }
}


export interface IConditionalFormProps {
  form?: WizardSchemas.Form.TSchema
  formValues: Record<string, string | number | boolean | Date | null | undefined | string[]>
  onSetFormValues?: (
    val: Record<string, string | number | boolean | Date | null | undefined | string[]>
  ) => void
  onCanSubmit?: (value: boolean) => void
  onShowReview?: (state: boolean) => void
  setResetState: (func: () => void) => void
  initialFormValues: TBaseRunVariable[]
  formReference?: string
  isReadOnly?: boolean
}

export function ConditionalForm({
  form,
  initialFormValues,
  formValues,
  onSetFormValues,
  setResetState,
  onShowReview,
  onCanSubmit,
  formReference,
  isReadOnly = false,
}: IConditionalFormProps) {
  const [completedSections, setCompletedSections] = useState(0)
  const [loomVideos, setLoomVideos] = useState<string[]>([])
  const [showReview, setShowReview] = useState(false)
  const [skippedSections, setSkippedSections] = useState<WizardSchemas.FormSection.TSchema[]>([])
  const [formSubmittedFromSection, setFormSubmittedFromSection] = useState<string | undefined>()
  const formWithoutNotes = form?.fields?.filter((field) => field.type !== 'note')
  const [checkListValue, setCheckListValue] = useState<TCheckListValue>({})
  const { showSnackbar } = useContext(SnackbarContext)
  const [error, setError] = useState(false)

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  setResetState(() => {
    setCompletedSections(0)
    setShowReview(false)
    onShowReview?.(false)
    setCheckListValue({})
    setFormSubmittedFromSection(undefined)
    onSetFormValues?.({})
  })

  const fieldComponents: {
    // eslint-disable-next-line @typescript-eslint/ban-types
    [key in WizardSchemas.FormFieldType.TSchema]?: FC<
      WizardSchemas.FormField.TSchema & {
        formType?: 'create' | 'update'
        id: string
        formValues: Record<string, any>
        key: string
      }
    >
  } = useMemo(
    () => ({
      code: ({ id, formValues }) => (
        <CodeWAC
          readOnly={isReadOnly}
          code={formValues[id] as string}
          onChangeMethod={(code: string) =>
            onSetFormValues?.({
              [id]: code,
            })
          }
        />
      ),
      input: ({ id, formValues }) => (
        <Input
          width='100%'
          value={formValues[id] as string}
          readOnly={isReadOnly}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onSetFormValues?.({
              [id]: e.target.value,
            })
          }
        />
      ),
      string: ({ id, formValues }) => (
        <Input
          width='100%'
          value={formValues[id] as string}
          readOnly={isReadOnly}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onSetFormValues?.({
              [id]: e.target.value,
            })
          }
        />
      ),
      multilinestring: ({ id, formValues }) => (
        <TextArea
          width='100%'
          value={formValues[id] as string}
          readOnly={isReadOnly}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            onSetFormValues?.({
              [id]: e.target.value,
            })
          }
        />
      ),
      richtext: ({ id, formValues }) => (
        <QuillEditor
          width='100%'
          value={(formValues[id] ?? '') as string}
          readOnly={isReadOnly}
          onChange={(v) =>
            onSetFormValues?.({
              [id]: v,
            })
          }
        />
      ),
      number: ({ id, formValues }) => {
        const fields: any = form?.fields?.find((field) => field.baseVariableId === id);
        const _min = fields?.min;
        const _max = fields?.max;
        return (
          <TextField
            size='small'
            fullWidth
            type='number'
            value={formValues[id]?.toString() as string}
            error={error}
            helperText={getNumberHelperText(_min, _max)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const val = e.target.valueAsNumber
              setError(!!(_min && (val < _min)) || !!(_max && (val > _max)))
              onSetFormValues?.({
                [id]: Number(val),
              })
            }}
            inputProps={{
              min: _min || "",
              max: _max || "",
              isReadOnly: isReadOnly,
            }}
          />
        )
      },
      url: ({ id, formValues }) => (
        <Input
          type='url'
          width='100%'
          value={formValues[id] as string}
          readOnly={isReadOnly}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onSetFormValues?.({
              [id]: e.target.value,
            })
          }
        />
      ),
      dropdown: ({ options, placeholder, id, key, formValues, label, isConditionalInput }) =>
        isConditionalInput ? (
          <Radio.Group
            key={key}
            name={key}
            onChange={(value) => {
              onSetFormValues?.({
                [id]: value,
              })
            }}
            selected={(formValues[id] as string) ?? null}
            orientation='vertical'>
            {options?.map((option) => (
              <Radio label={option.value as string} value={option.key} />
            ))}
          </Radio.Group>
        ) : (
          <Dropdown
            key={key}
            width='100%'
            selectedKey={(formValues[id] as string) ?? null}
            options={options ?? []}
            disabled={isReadOnly}
            onChange={({ key }) =>
              onSetFormValues?.({
                [id]: key,
              })
            }
            placeholder={placeholder}
            name={key}
          />
        ),
      date: ({ id, formValues }) => (
        <DateTimePicker
          onChange={(date) => {
            if (date !== null && isNaN(date.getTime())) {
              showSnackbar({
                message: 'Please enter a valid date in the format MM/DD/YYYY.',
                variant: 'error',
              })
              return
            }
            const formattedDate = date ? format('yyyy-MM-dd', date) : null
            onSetFormValues?.({
              [id]: formattedDate,
            })
          }}
          value={formValues[id] ? zonedTimeToUtc(formValues[id], timeZone) : null}
          disabled={isReadOnly}
          fullWidth
          hideTime
        />
      ),
      datetime: ({ id, formValues }) => (
        <DateTimePicker
          onChange={(date) => {
            if (date !== null && isNaN(date.getTime())) {
              showSnackbar({
                message: 'Please enter a valid date time in the format MM/DD/YYYY HH:MM.',
                variant: 'error',
              })
              return
            }
            onSetFormValues?.({
              [id]: date?.toISOString(),
            })
          }}
          value={formValues[id]}
          disabled={isReadOnly}
          fullWidth
          hideTime={false}
        />
      ),
      boolean: ({ id, formValues }) => (
        <NullSwitch
          isOn={formValues[id]}
          disabled={isReadOnly}
          onToggle={(value) =>
            onSetFormValues?.({
              [id]: value,
            })
          }
        />
      ),
      file: ({ id, uploadMeta }) => (
        <FileUploaderDropzone
          disabled={isReadOnly}
          acceptedFileTypes={uploadMeta?.acceptedFiles}
          directoryName={uploadMeta?.directoryName ?? 'manticore'}
          saveUploadedFiles={(files: UploadedFile[]) => {
            // We get the array of all fileLinks in the format specified in the Form Configuration
            const fileLinks = files.map((file: UploadedFile) => {
              switch (uploadMeta?.linkFormat ?? 'url') {
                case 'url':
                  return file.url
                case 'file_path':
                  return file.filePath
              }
            })

            switch (uploadMeta?.outputFormat ?? 'string') {
              case 'string':
                onSetFormValues?.({ [id]: fileLinks.join(', ') })
                break

              case 'a_string':
                onSetFormValues?.({ [id]: fileLinks as string[] })
                break
            }
          }}
        />
      ),
      radio: ({ options, id, formValues, label, key }) => (
        <Box
          sx={{
            maxHeight: '200px',
            overflowY: 'auto',
          }}>
          <Radio.Group
            key={key}
            onChange={(selected) =>
              onSetFormValues?.({
                [id]: selected,
              })
            }
            name={key as string}
            selected={(formValues[id] as string) ?? null}
            orientation='vertical'>
            {options?.map((option) => (
              <Radio
                label={option.key}
                key={option.key}
                value={option.value as string}
                disabled={isReadOnly}
              />
            ))}
          </Radio.Group>
        </Box>
      ),
      multiselect: ({ options, id, key, formValues, label }) => (
        <MultiSelect
          maxHeight='200px'
          key={key}
          name={label as string}
          options={options ?? []}
          disabled={isReadOnly}
          selectedKeys={formValues[id] ? formValues[id].split(',') : null}
          onSelect={(selected) => {
            onSetFormValues?.({
              [id]: selected.map((s) => s.value).toString(),
            })
          }}
        />
      ),
      loomvideo: ({ id, formValues }) => {
        const onLoading = () => (
          <div className='mb-5 h-8 w-full'>
            <Button variant='secondary' size='md' disabled={true}>
              Loom is starting up
            </Button>
          </div>
        )

        const LoomButton = dynamic(() => import('@invisible/loom'), {
          ssr: false,
          loading: onLoading,
        })
        const url = formValues[id]

        return (
          <div className='flex flex-col gap-2'>
            {url ? <EmbedLoomVideo url={url} /> : null}
            <LoomButton
              loomVideos={loomVideos}
              setLoomVideos={(videos) => setLoomVideos(videos)}
              handleChange={(name, videos) =>
                onSetFormValues?.({
                  [id]: videos[videos.length - 1],
                })
              }
              label={url ? 'Update the Loom Video' : 'Add a Loom Video'}
            />
          </div>
        )
      },
    }),
    [formReference, form?.fields, error]
  )

  const renderField = (field: WizardSchemas.FormField.TSchema) => (
    <div className='mb-5 flex items-center' key={`${formReference}-${field.id}`}>
      {field.type === 'note' ? (
        <div className='flex items-center gap-1 text-gray-400'>
          <InfoFilledIcon />
          <span>{field.note}</span>
        </div>
      ) : (
        <div
          className={classNames(
            'w-full',
            field.type === 'boolean' ? 'flex flex-row-reverse items-center justify-end gap-3' : ''
          )}>
          <div className={field.type === 'boolean' ? '' : 'mb-2'}>
            {field.label}
            {field.required ? '*' : ''}
          </div>
          <div className='shrink-0'>
            {fieldComponents[field.type as keyof typeof fieldComponents]?.({
              ...field,
              formType: form?.type,
              formValues,
              id: field.baseVariableId as string,
              key: uuid(),
            })}
          </div>
        </div>
      )}
    </div>
  )

  const handleNextSection = (
    nextSection: string | undefined,
    shouldSubmit: boolean | undefined,
    section: WizardSchemas.FormSection.TSchema
  ) => {
    const formSections = form?.sections ?? []
    const currentSectionIndex = formSections.findIndex(({ id }) => id === section.id)
    const nextSectionId = nextSection ?? formSections?.[currentSectionIndex + 1]?.id
    const nextSectionIndex = formSections.findIndex(({ id }) => id === nextSectionId)

    setSkippedSections((prev) => prev.filter((s) => s.id !== nextSectionId))

    if (shouldSubmit) setFormSubmittedFromSection(nextSectionId)
    else setFormSubmittedFromSection(undefined)

    if (!nextSection) {
      setCompletedSections((prevState) => prevState + 1)
    } else {
      const sectionsToSkip = (form?.sections ?? []).filter(
        ({ id }, index) => index < nextSectionIndex && index > currentSectionIndex
      )

      onSetFormValues?.({
        ...(formWithoutNotes ?? [])
          .filter(({ sectionId }) => sectionsToSkip.some((section) => section.id === sectionId))
          .filter(
            ({ sectionId, baseVariableId }) =>
              !(formWithoutNotes ?? []).some(
                (field) => field.sectionId !== sectionId && field.baseVariableId === baseVariableId
              )
          )
          .map(({ baseVariableId }) => ({
            baseVariableId,
          }))
          .reduce(
            (a, v) => ({
              ...a,
              [v.baseVariableId as string]:
                initialFormValues.find(
                  (initialValue) => v.baseVariableId === initialValue.baseVariableId
                )?.value ?? null,
            }),
            {}
          ),
      })
      setCheckListValue((prev) => ({
        ...prev,
        ...(form?.checkLists ?? [])
          .filter(({ sectionId }) => sectionsToSkip.some((section) => section.id === sectionId))
          .map((checkList) => checkList.checkItems ?? [])
          .reduce((acc, val) => acc.concat(val), [])
          .reduce((a, v) => ({ ...a, [v.id as string]: null }), {}),
      }))
      setCompletedSections(nextSectionIndex)
      setSkippedSections((prev) => [...prev, ...sectionsToSkip])
    }
  }

  const hasMissingRequiredField = useMemo(
    () =>
      (form?.fields ?? [])
        .filter(
          (f) =>
            f.required &&
            !skippedSections.some((skippedSection) => f.sectionId === skippedSection.id) &&
            !(form?.sections ?? [])
              .filter((section, index) => {
                if (formSubmittedFromSection)
                  return (
                    index >
                    (form?.sections ?? []).findIndex(
                      (section) => section.id === formSubmittedFromSection
                    )
                  )
                return false
              })
              .some((section) => section.id === f.sectionId)
        )
        .reduce((result, requiredField) => {
          if (
            requiredField.baseVariableId &&
            (isNil(formValues[requiredField.baseVariableId]) ||
              formValues[requiredField.baseVariableId] === '')
          )
            result = true
          return result
        }, false),
    [formValues, form?.fields, skippedSections]
  )

  const hasMissingChecks = useMemo(
    () =>
      (form?.checkLists ?? [])
        .filter(
          (checkList) =>
            !skippedSections.some((skippedSection) => checkList.sectionId === skippedSection.id) &&
            !(form?.sections ?? [])
              .filter(
                (section, index) =>
                  index >
                  (form?.sections ?? []).findIndex(
                    (section) => section.id === formSubmittedFromSection
                  )
              )
              .some((section) => section.id === checkList.sectionId)
        )
        .map((checkList) => checkList.checkItems ?? [])
        .reduce((acc, val) => acc.concat(val), [])
        .filter((checkItem) => checkItem.isRequired)
        .reduce((result, checkItem) => {
          if (!checkListValue?.[checkItem.id]) result = true
          return result
        }, false),
    [checkListValue, form?.checkLists, skippedSections]
  )

  useEffect(() => {
    onCanSubmit?.(!hasMissingChecks && !hasMissingRequiredField)
  }, [hasMissingChecks, hasMissingRequiredField])

  return (
    <div>
      {form?.fields?.filter((field) => isEmpty(field.sectionId)).map(renderField)}
      {size(form?.sections) > 1 ? (
        <Card className='!min-h-0'>
          <Progress
            color='#4DBC25'
            progress={showReview ? 1 : completedSections / (form?.sections?.length ?? 1)}
            width='100%'
          />
        </Card>
      ) : null}
      {!isEmpty(form?.sections) ? (
        !showReview ? (
          <Fragment>
            {form?.sections
              ?.filter((section, idx) => idx < completedSections)
              .map((section) => (
                <Section
                  key={section.id}
                  section={section}
                  isCompleted
                  goToSection={(nextSection) =>
                    setCompletedSections(
                      (form?.sections ?? []).findIndex((s) => s.id === nextSection)
                    )
                  }
                  isSkipped={skippedSections.some(({ id }) => section.id === id) ?? false}
                />
              ))}
            {form?.sections
              ?.filter((section, idx) => idx === completedSections)
              .map((section) => (
                <Section
                  key={section.id}
                  section={section}
                  isOnlySection={size(form?.sections) === 1}
                  isCompleted={false}
                  checkLists={form?.checkLists?.filter(
                    (checkList) => checkList.sectionId === section.id
                  )}
                  checkListValue={checkListValue}
                  fields={form?.fields?.filter((field) => field.sectionId === section.id)}
                  formValues={formValues}
                  formSubmittedFromSection={formSubmittedFromSection}
                  onNextSection={(nextSection, shouldSubmit) =>
                    handleNextSection(nextSection, shouldSubmit, section)
                  }
                  hasNextSection={completedSections + 1 < (form?.sections ?? []).length}
                  onReview={() => {
                    onShowReview?.(true)
                    setShowReview(true)
                  }}>
                  {form?.fields
                    ?.filter((field) => field.sectionId === section.id)
                    .map((field) => renderField(field))}
                  {form?.checkLists
                    ?.filter((checkList) => checkList.sectionId === section.id)
                    .map((checkList) => (
                      <CheckList
                        key={checkList.id}
                        checkList={checkList}
                        checkListStatus={checkListValue}
                        setCheckListValue={setCheckListValue}
                      />
                    ))}
                </Section>
              ))}
          </Fragment>
        ) : (
          <div>
            {size(form?.sections) > 1 ? (
              <Preview
                form={form!}
                checkListValue={checkListValue}
                formSubmittedFromSection={formSubmittedFromSection}
                skippedSections={skippedSections}
                formValues={formValues}
              />
            ) : null}
          </div>
        )
      ) : null}
    </div>
  )
}

export default ConditionalForm
